import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Cta } from '@interfaces/config';
import { RouterActions } from '@store/router';
import { FormsModule } from '@angular/forms';
import { SpsStorageService } from '@services/storage/sps-storage.service';
import { LOCALSTORAGE_SPS_WEBINAR_KEY, LOCALSTORAGE_SOCIAL_WIDGET_EXPIRY } from '@core/config';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ImageComponent } from '@components/common/_media/image/image.component';
import { ButtonTypeEnum } from '@models/enums';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { ButtonComponent } from '@components/common/_buttons/button/button.component';
import { IconComponent } from '@components/common/icon/icon.component';
import { WebinarDialog } from '@interfaces/config';
import { SanitizedHtmlComponent } from '@components/common/sanitized-html/sanitized-html.component';
import { ButtonLinkComponent } from '@components/common/_buttons/button-link/button-link.component';
import { environment } from '../../../../../environments/environment';
import { CmsRestApiService } from '@services/api/cms-rest-api/cms-rest-api.service';
import { VideoComponent } from '@components/common/_media/video/video.component';
import {

  LanguagesEnum

} from '@core/config';

@Component({
  standalone: true,
  selector: 'sps-webinar-dialog',
  templateUrl: './webinar-dialog.component.html',
  styleUrls: ['../base-dialog/base-dialog.component.scss', './webinar-dialog.component.scss'],
  imports: [VideoComponent, FormsModule, ImageComponent, ButtonLinkComponent, SanitizedHtmlComponent, ReactiveFormsModule, TranslateModule, IconComponent, ButtonComponent],
})

export class WebinarDialogComponent extends BaseDialogComponent {
  public form: FormGroup<{ dns: FormControl<string> }>;
  public config: WebinarDialog.ComponentConfig;
  public active: boolean = false;
  public isChecked: boolean = false;
  public httpClient = inject(HttpClient);
  constructor(private http: HttpClient, private store: Store, private fb: FormBuilder, private translateService: TranslateService, private location: Location) {
    super();

    this.initForm();
  }



  public reset(): void {
    this.form.reset();
  }



  public hideWebinarDialog(): void {


    // mark the storage key 
    this.close();


  }

  public onCheckboxChange(e: any): void {
    //  console.log(event);
    let username = "webinar-dialog"

    if (e.target.checked) {
      const expires = Date.now() + LOCALSTORAGE_SOCIAL_WIDGET_EXPIRY;

      SpsStorageService.saveEntry(LOCALSTORAGE_SPS_WEBINAR_KEY, { username, expires });


    } else {
      SpsStorageService.removeEntry(LOCALSTORAGE_SPS_WEBINAR_KEY);
    }
    this.close();
  }
  public submit(): void {

  }
  public gotoCTA(): void {


    // this.store.dispatch(
    //   RouterActions.navigate({
    //     url: this.config.cta.link.href

    //   })
    // );

    this.close();
  }
  private initForm(): void {

    let path = this.location.path();
    const selectedLanguage = CmsRestApiService.extractLanguage(path) || LanguagesEnum.EN;


    this.httpClient.get(`${environment.apiHost}/${selectedLanguage}/jsonapi/menu_link_content/campaign-${selectedLanguage}`)
      .subscribe({
        next: (data: any) => {


          const includedArray = data.included;
          const result = includedArray.filter((included: any) => included.type === "paragraph--text_media_box");
          const attributes = result[0].attributes;
          const title = attributes.field_title_mediabox;
          const description = attributes.field_sub_headline_mediabox.processed;
          const cta = attributes.field_cta_link_mediabox;

          let largeImageUrl: string;
          const fileObjects = includedArray.filter((included: any) => included.type === "file--file");
          if (fileObjects && fileObjects.length > 0 && fileObjects[0].attributes.image_style_uri) {
            largeImageUrl = fileObjects[0].attributes.image_style_uri.webp;

          }
          let videoUrl = null;
          if (!largeImageUrl && fileObjects && fileObjects.length > 0) {
            videoUrl = fileObjects[0].attributes.uri.url;

          }

          let media = null;
          if (videoUrl)
            media = {
              type: 'VIDEO',
              url: videoUrl,
              caption: null,
              metas: null,
              thumbnail: null
            }

          console.log("data", largeImageUrl);
          this.config = {
            image: largeImageUrl,
            media: media,
            title: title,
            description: description,


            cta:

            {
              text: 'LEARN MORE ABOUT GEN AI',
              subText: 'Does this appear?',
              link: { label: cta.title, href: cta.url, external: false },
              image: null,
              buttonType: ButtonTypeEnum.PRIMARY_DARK,
              layout: Cta.Layout.STANDARD,
              nodeId: 'node-cc22a2b2-992f-4978-a8f6-e518499c0017'
            }
          };

        }, error: (err) => console.log(err)
      });





  }
}